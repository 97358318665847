let Config = {
  PERSIST_SECRET_KEY: "DRA_WEB_SECRET_KEY",
  ENVIRONMENT: "DEVELOPMENT",
  ENVIRONMENTS: {
    LOCAL: {
      API_URL: "https://dev-api-admin.digitalrange.com",
      BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/admin.digitalrange.dev",
      WEBCHAT_URL_DOMAIN: "https://dev-widget.digitalrange.com/"
    },
    DEVELOPMENT: {
      API_URL: "https://dev-api-admin.digitalrange.com",
      BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/admin.digitalrange.dev",
      WEBCHAT_URL_DOMAIN: "https://dev-widget.digitalrange.com/"
    },
    PRODUCTION: {
      API_URL: "https://dev-api-admin.digitalrange.com",
      BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/admin.digitalrange.dev",
      WEBCHAT_URL_DOMAIN: "https://dev-widget.digitalrange.com/"
    }
  }
};

Config.env = () => {
  return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;